<template>
  <div class="page-consultancy layout-horizontal">
    <consultancy
        class="layout-flexible"
        :organization-id="organizationId"
        :account-id="accountId"
        style="max-width: 800px;"
    ></consultancy>
  </div>
</template>

<script>
import Consultancy from "@/pages/consultancy/panel/Consultancy";

export default {
  name: "ConsultancyPanel",
  components: {Consultancy},
  props: {
    organizationId: Number,
    userId: Number,
    accountId: Number,
  },

}
</script>

<style scoped>

.page-consultancy {
  width: 100%;
  background-color: white;
}


</style>